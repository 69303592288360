@import "vars";

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .ui.container {
    margin-left: inherit !important;
    margin-right: inherit !important;
  }
  .content {
    @media only screen and (max-width: 767px) {
      > .ui.segment {
        padding-left: inherit;
        padding-right: inherit;
      }
    }
    .col-right {
      padding: inherit !important;
      margin: 0 !important;
    }
    .ui.grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

html, body {
  background: url('../../src/images/bg.png') repeat;
}

body {
  margin: 0;
  padding: 0;
  font-family: $fontStack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 1199px) {
  .ui.container {
    width: inherit;
  }
}

.acrblue {
  color: $ACRBlue;
}

.content-title {
  font-weight: bold;
  font-size: $contentTitleSize;
}

.content-help-text {
  font-size: $contentHelpTextSize
}

.ui.segment {
  box-shadow: none;
}

.top-bar {
  background-color: $lightGray;
  border-bottom: 1px lightgray solid;
  border-top: 3px #0099CC solid;
  .ui.grid {
    margin: inherit;
    .column:not(.row) {
      padding-top:inherit;
      padding-bottom:inherit;
    }
    .column:not(.row), .column {
      display: block;
    }
    .column:not(.row) {
      font-size: 0.8em;
    }
  }
}

.wrapper-open {
  position: absolute !important;
}

.search-section-wrapper {
  @media only screen and (max-width: 767px) {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999999;

  }
}

.search-section {
  background-color: white;
  border-bottom: 1px lightgray dashed;
  .ui.grid {
    margin: inherit;
    .col {
      padding-top: 1em;
    }
  }
  .input {
    width: 100%;
  }
  .ui.image img {
    height: 3.5em;
  }
}

.search-section-mobile {
  background-color: white;
  border-bottom: 1px lightgray dashed;
  .ui.grid > .column:first-child {
    padding: 1.5em 0 0 0.5em;
  }
  .ui.grid {
    margin: inherit;
    .col {
      padding-top: 1em;
    }
  }
  .input {
    width: 100%;
  }
  .ui.image img {
    height: 3.5em;
  }
}


.header-menu {
  background-color:  #1B1C1D;
  border-bottom: 1px lightgray solid;
  .ui.menu {
    .active {
      font-weight: bold;
    }
  }
  .ui.text.menu {
    margin: inherit;
  }
  .has-sub-menu {
    position: relative;
  }
  .sub-menu {
    background-color:white;
    position: absolute;
    top:2.9em;
    left:0;
    display: none;
    z-index: 99999;
    &.active {
      display: block;
    }
    .menu-title {
      margin-left: 0.2em;
      font-size: 1.1em;
      color: $ACRBlue;
      margin-top: 1em;
    }
    .ui.menu {
      border:inherit;
    }
    .item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      color:black !important;
    }
    .ui.vertical.menu .item > i.icon {
      float: none;
      margin:0 0.3em 0 0;
    }
  }
}

.menu-mobile {
  background-color: white;
  position: absolute;
  width: 100%;
  min-height: 100%;

  .menu-block {
    width: 100%;
    margin-top:0.3em;
    margin-bottom:1.5em;
    div:first-child {
      padding-left: 2.5em;
      font-size: 1.3em;
      font-weight: bold;
    }
    div:last-child {
      padding-left: 4em;
      a {
        width: 100%;
        display: inline-block;
        font-size: 1.1em;
        margin-top: 1em;
      }
    }
  }
}

.ui.dimmer {
  position: fixed !important;
}

.banner {
  background: url('../../src/images/banner.jpg') 0 -26em no-repeat;
  width: 100%;
  height: 31em;
  .container {
    padding-top:1em;
    padding-left: 17em;
    filter: inherit;
    h1 {

    }
    q {
      color:white;
      font-style: italic;
    }

    button {
      margin-top:2em;
    }
  }
}

.content {
  background-color: white;
  margin-top: 1em;
  .ui.segment {
    border:1px lightgray solid;
    border-radius: 0.2em;
    .ui.header>.icon {
      font-size: 1em;
    }
  }
  .col-right {
    .ui.divider {
      //margin:0 1em 0.7em 1em;
      border-top:1px $ACRBlue solid;
    }
  }
  @media only screen and (max-width: 460px) {
    width: 100%;
  }
}



footer {
  padding-top: 1.5em;
}

.footer-brands {
  background-color: $lightGray;
  border-top: 1px lightgray solid;
  padding: 1.5em;

  .image {
    filter: grayscale(100%);
    opacity: 0.4;
    &:hover {
      filter:grayscale(0);
      opacity: 1;
    }
  }
}




.segment-block {
  clear: both;
  width: 100%;
  overflow: auto;
  .col-left {
    float:left;
    width: 2em;
  }
  .col-right {
    float:left;
  }

  .no-width {
    width: inherit;
  }
  .ui.button {
    margin: 1em 0 1em 0;
  }
  a {
    text-decoration: underline;
  }
}


%homepage-blocks {
  clear: both;
  margin: 0.5em;
  overflow: hidden;

  a {
    width: 100%;
    @media only screen and (max-width: 460px) {
      width: 100%;
    }
    @media only screen and (min-width: 460px) and (max-width: 990px) {
      width: 50%;
    }
    @media only screen and (min-width: 990px) and (max-width: 1200px) {
      width: 33%;
    }
    @media only screen and (min-width: 1200px) {
      width: 25%;
    }

    float:left;
    padding:0.5em;

    &:hover {
      .ui.segment {
        border:1px $ACRBlue solid;
      }
    }

    h3 {
      margin:1em 0 0 0;
    }

    .ui.segment {
      min-height: 19em;
      span {
        color:gray;
        font-size: 0.9em;
      }
    }
  }
}


.content-products {
  width: 100%;
  .course-blocks {
    @extend %homepage-blocks;
  }
  .news-blocks {
    @extend %homepage-blocks;
    a {
      .ui.segment {
        min-height: 25em;
      }
    }
  }

  .product-badge {
    position: absolute;
    top:1em;
    left:1em;
    background-color: #fddb00;
    box-shadow: 0 0 0.25em rgba(215,215,215,.9);
    color:black !important;
    padding: 0.3em;
  }



  .ui.list > a.item {
    color:dimgray;
    &:hover {
      color: $ACRBlue;
    }
    i.icon {
      color:$ACRBlue;
      margin-left:1em;
      margin-right: 0.4em;
      float:left;
    }
    span {
      float:left;
      text-decoration: underline;
    }
  }
}



.promo-banner {
  background-color: #9BBDBE;
  height: 150px;
  margin: 2em 1em 2em 1em;
}

.news-grid {
  .ui.segment {
    min-height: 25em;
  }
  span {
    color:gray;
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid > .row > .column {
    margin-right: -2em !important;
  }
}


.lotus-specialist {
  >div:first-child {
    //float:left;
    padding-right: 1em;
    text-align: justify;
    //width: 50%;
  }
  //>div:last-child {
  //  float:right;
  //  width: 50%;
  //  text-align: right;
  //  img.ui.image {
  //    display: inline-block;
  //  }
  //}
  .content-image {
    q {
      color: gray;
      font-style: italic;
    }
  }
}

.site-page-navation {
  margin-top: 1em;
  padding-left: 1em;
  a {
    text-decoration: underline;
  }
  a, span {
      text-transform: capitalize;
  }

  .last {
    color:gray;
  }
}

.site-title {
  font-size: 1.4em;
  padding-top: 0.5em;
  font-weight: bolder;
  color: $ACRBlue;
  &.mobiles {
    padding-top:0;
  }
}

.site-description {
  padding-top: 0.3em;
}





