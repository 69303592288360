@import "../../vars";

.footer-footer {
  background-color: white;
  border-top: 1px lightgray solid;

  .ui.grid {
    padding: inherit;
    margin: inherit;
    .column:not(.row) {
      padding:inherit;
    }
  }
  img {
    padding-top: 0.8em;
    @media only screen and (max-width: 768px) {
      margin-left: 2em;
    }
  }
  ol {
    list-style: none;
    float:right;
    padding-inline-start:20px;
    li {
      float:left;
      margin-left: 1em;
      color:gray;
    }
  }
}
